var tlaInit = (function (window) {

  var tlaServiceUri = "#{AppSettings.PublisherServiceUrl}api/v1/";

  //Create new GUID for local/session storage
  function createGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  }

  function getTlaQuery(key) {
    try {
      var urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(key);
    } catch (e) {
      return null;
    }
  }

  // Get the tlaUserFp
  function getTlaUserFp() {
    // Generate new fp
    var newFp = createGuid();
    _tla("track", "fp", newFp);

    return newFp;
  }

  function execFunction(obj, args) {
    //remove first argument as always key. Pass remaining args to function
    var key = args.shift();

    //if named function exists then apply it
    if (obj[key]) {
      //apply function listed in key
      obj[key].apply(obj, args);
    }
  }

  function checkProp(propId) {
    //Requires network-publisher-site
    var pattern = /^[a-z]{3}[0-9]{2}-[0-9a-f]{7}-[0-9a-f]{3}$/i;

    if (pattern.test(propId)) {
      void 0;
      return true;
    }

    return false;
  }

  function getHostUrl(isIframe) {
    void 0;
    void 0;
    void 0;

    var refMacroUrl = window['_tlaRefMacro'];

    if (refMacroUrl && refMacroUrl.length > 0 && refMacroUrl.toLowerCase().startsWith("http")) {

      return unescape(refMacroUrl);
    }

    var hostUrl = document.location.href;

    if (isIframe) {
      // Use Referrer to ge parent URL
      var refUrl = document.referrer;
      if (refUrl.length > 0) {
        hostUrl = refUrl;
      }
    }

    return hostUrl;
  }

  var scrollStop = function (callback) {
    // Make sure a valid callback was provided
    if (!callback || Object.prototype.toString.call(callback) !== '[object Function]') return;

    document.addEventListener('mouseout', function (event) {

      // Set an internal to run after 
      setInterval(function () {
        if (event.clientY <= 0) {
          // Run the callback
          callback();
        }
      }, 66);
    });

    // Setup scrolling variable
    var isScrolling;

    // Listen for scroll events
    window.addEventListener('scroll', function (event) {

      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(function () {

        // Run the callback
        callback();

      }, 66);

    }, false);

  };

  // Check if a given element is visible in the viewport.
  var isInViewport = function (elem) {
    var bounding = elem.getBoundingClientRect();

    // Ensure element has height.
    if (bounding.height < 1) {
      return false;
    }

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  // Check if a given zone element is visible in the viewport.
  function checkVisible(elementName) {
    // Cross domain iframe functionality not implemented yet. Will always return visible for IFrame currently.
    var cont = document.getElementById(elementName);
    var override = cont.querySelector(".tla-zv");

    if (override === null) {
      return isInViewport(cont);
    } else {
      return isInViewport(override);
    }
  }

  // Check if a given element is has parent with a given class.
  function hasParenWithClass(el, cls) {
    // If we are here we didn't find the searched class in any parents node
    if (!el.parentNode) {
      return false;
    }

    // If the current node has the class return true, otherwise we will search
    // it in the parent node
    if (el.className && new RegExp("(\\s|^)" + cls + "(\\s|$)").test(el.className)) {
      return true;
    }

    return hasParenWithClass(el.parentNode, cls);
  }

  // Add click event tracker on the zone container.
  function addZoneClickTracker(container, respJson, zoneId, selfTrackingClick) {
    var cont = document.getElementById(container);
    cont.addEventListener("click", function (e) {
      tla.updateZoneViewStatus(container, respJson, zoneId);
      setInterval(function () { tla.updateZoneViewStatus(container, respJson, zoneId); }, 300);

      _tla("templateContentClick", "KeyPress", e, respJson.templateId, respJson.productId, respJson.campaignLineItemId, respJson.contentSetVersionId, zoneId, respJson.campaignDeliveryTypeIds.join(","));

      var elm = e.target || e.srcElement;
      if (!selfTrackingClick && hasParenWithClass(elm, "tla-zc")) {
        _tla("templateContentClick", "Click", e, respJson.templateId, respJson.productId, respJson.campaignLineItemId, respJson.contentSetVersionId, zoneId, respJson.campaignDeliveryTypeIds.join(","));
      }
    });

    var aar = cont.getElementsByTagName("a");
    for (var j = 0; j < aar.length; j++) {
      aar[j].addEventListener("click", function (e) {
        if (!selfTrackingClick) {
          _tla("templateContentClick", "Click", e, respJson.templateId, respJson.productId, respJson.campaignLineItemId, respJson.contentSetVersionId, zoneId, respJson.campaignDeliveryTypeIds.join(","));
        }
      });
    }
  }

  // Picks items off the global queue and executes them
  function processQueue(obj, queue, execFnc) {
    try {

      while (queue.length > 0) {
        //get the first item from the queue
        var itm = queue.shift();

        execFnc(obj, Array.prototype.slice.call(itm));

      } //loop
    } catch (ex) {
      void 0;
    }
  }

  // Add function to list
  function fnWrap(key, obj, callback) {
    //assign function to list 
    obj[key] = callback;
  }

  // Get Content by AJAX.
  function getByXhr(uri, callback, targetContainer, zoneId) {
    var Xhr = window.XMLHttpRequest;
    void 0;
    if (!Xhr) return false;

    var request = new Xhr;

    if (!("withCredentials" in request)) return false;

    request.open("GET", uri, true);

    request.withCredentials = false;
    //Send as plain text rather than JSON to prevent preflight call
    //Also, senBeacon sends as plain text so consistent.
    request.setRequestHeader("Content-Type", "text/plain");

    request.onreadystatechange = function () {
      if (4 === request.readyState && 200 === request.status) {
        callback(request.responseText, targetContainer, zoneId);
        request = null;
      }
    };

    request.send();

    return true;
  }

  // Send by AJAX.
  function postByXhr(uri, data) {
    var Xhr = window.XMLHttpRequest;
    void 0;
    if (!Xhr)
      return false;

    var request = new Xhr;

    if (!("withCredentials" in request))
      return false;

    request.open("POST", uri, true);

    request.withCredentials = false;
    //Send as plain text rather than JSON to prevent preflight call
    //Also, senBeacon sends as plain text so consistent.
    request.setRequestHeader("Content-Type", "text/plain");
    request.onreadystatechange = function () {
      4 === request.readyState && (request = null);
    };
    request.send(data);

    return true;
  }

  // Post the given data to the service API.
  function postData(uri, data) {
    //Try sending by each method
    if (8192 >= data.length) {
      void 0;
      postByXhr(uri, data);
    }
  }

  var domReady = function (callback) {
    document.readyState === "interactive" || document.readyState === "complete" ? callback() : document.addEventListener("DOMContentLoaded", callback);
  };

  // BEGIN TLA OBJECT
  var tla = function () {
    void 0;
    //execute function request
    execFunction(tla, Array.prototype.slice.call(arguments));
  };

  tla.timestamp = 0; //var to hold timestamp passed in from publisher page. We can use this to compare against further timestamps for load speed etc
  tla.tracker; //object to hold tracking data for page impression recording
  tla.multiFrame = false; //used to prevent re-running setup/tracking where we have multipe IFrame instances on a page (multiple zones). Other than this, IFrame instances will not know about each other

  tla.propertyId = ""; //'PropertyId' represents a publisher property - format is NETWORK-PUBLISHER-WEBSITE [a-z]{3}[0-9]{2}-[0-9a-f]{7}-[0-9a-f]{3}
  tla.fp; // a GUID generated user Id
  tla.userSession; // a GUID for the user session generated here
  tla.isIframe = !(window === window.parent); //returns whether IFrame
  tla.currPage = getHostUrl(tla.isIframe); //returns the current URL/parent URL when IFrame

  tla.viewedZones = {}; //Dictionary object

  tla.initiated = false; //used to test if TLA object has already been assigned to _tla
  tla.context = ""; //Var to hold publisher context data if passed in

  tla.initialise = function () {
    //_tla is the global object name created in the page script that holds the command queue
    var tlaObject = window["_tla"];

    //check for new instance - no need to do this if not
    if (!tlaObject.initiated) {
      //add intial page script vars to TLA object
      tla.queue = tlaObject.queue;

      var tlaTs = getTlaQuery('tlaTs');
      if (tlaTs) {
        tla.timestamp = tlaTs;
      } else {
        tla.timestamp = tlaObject.timestamp;
      }

      //Create array of client functions (so we can refer to by key later)
      fnWrap("create", tla, tla.create);
      fnWrap("track", tla, tla.track);
      fnWrap("iframe", tla, tla.iframe);
      fnWrap("getContent", tla, tla.getContent);
      fnWrap("setContext", tla, tla.setContext);
      fnWrap("templateContentClick", tla, tla.templateContentClick);
      fnWrap("conversion", tla, tla.conversion);
      fnWrap("clientConversion", tla, tla.clientConversion);
      //assign TLA to global obj
      window["_tla"] = tla;
      //console.log(_tla.queue.length);

      //Now process the queue to handle commands set while js file was loading async
      processQueue(tla, tla.queue, execFunction);

      void 0;
    }
  };

  //Create - function sets up tracker
  tla.create = function (propId) {
    void 0;
    if (!tla.initiated && checkProp(propId)) {
      void 0;
      tla.propertyId = propId;
      tla.propOk = true;

      var userSession = createGuid();
      var fp = getTlaUserFp();
      //check if user id exists(multiple zones!-new uid could have been created but not yet stored) then read user localStorage GUID to get user id.
      tla.fp = tla.fp || fp || "";
      //check if user session exists, then read user sessionStorage GUID to get the user session id 
      tla.userSession = tla.userSession || userSession || "";

      void 0;
      void 0;

      //##TODO - what else do we need to do here? ##

      tla.initiated = true;
    }
  };


  //Track - gathers tracking data into TLA object
  tla.track = function (action, args) {
    void 0;
    void 0;
    if (tla.propOk) {
      switch (action) {
        case "event":
          postData(tlaServiceUri + "post/", JSON.stringify(args));
          void 0;
          break;
        case "page":
          if (!tla.multiFrame) {
            void 0;
            var data = {
              et: "Page",
              fp: tla.fp,
              usid: tla.userSession,
              pid: tla.propertyId,
              ts: tla.timestamp,
              url: tla.currPage,
              tz: (new Date()).getTimezoneOffset() / 60,
              hn: document.location.hostname,
              hp: document.location.port,
              pt: document.location.protocol,
              hr: document.location.href,
              rf: document.referrer
            };
            //Send impression track to API
            postData(tlaServiceUri + "post/", JSON.stringify(data));
          }
          break;
        case "fp":
          void 0;
          tla.tracker = new ImpressionTracker(tla, args);
          //Send impression track to API
          postData(tlaServiceUri + "post/", JSON.stringify(tla.tracker.data));
          break;
        default:
          break;
      }
    }
  };

  tla.updateZoneViewStatus = function (container, respJson, zoneId) {
    void 0;
    if (tla.viewedZones[zoneId] === false && (checkVisible(container) || tla.isIframe)) {
      setTimeout(function () {
        if (tla.viewedZones[zoneId] === false && (checkVisible(container) || tla.isIframe)) {
          //fire content viewed event
          void 0;
          tla.viewedZones[zoneId] = true;

          if (respJson.templateId) {
            var data = {
              et: "AdvertView",
              fp: tla.fp,
              usid: tla.userSession,
              pid: tla.propertyId,
              ts: tla.timestamp,
              url: tla.currPage,
              tz: (new Date()).getTimezoneOffset() / 60,
              cta: respJson.templateId,
              prid: respJson.productId,
              cli: respJson.campaignLineItemId,
              csv: respJson.contentSetVersionId,
              zn: zoneId,
              cdtv: respJson.campaignDeliveryTypeIds.join(",")
            };
            _tla("track", "event", data);
          }
        }
      }, 300);
    }
  };

  tla.setContext = function () {
    tla.context = Array.prototype.slice.call(arguments);
  };

  tla.clientConversion = function (obj) {
    // Array = 
    // 0 = Use query string vars
    // 1 = cta,
    // 2 = csv
    // 3 = zn
    // 4 = type
    // 5 = value
    var isObjArray = Array.isArray(obj);

    if (isObjArray) {
      var arg1 = {
        et: "ClientConversion",
        fp: tla.fp,
        usid: tla.userSession,
        pid: tla.propertyId,
        ts: tla.timestamp,
        url: tla.currPage,
        tz: (new Date()).getTimezoneOffset() / 60,
        cta: obj[1],
        csv: obj[2],
        zn: obj[3],
        val: obj[5],
        valt: obj[4]
      };

      _tla("track", "event", arg1);
    } else {
      // Check the data is in the query string.
      var cta = getTlaQuery("tlaCta"),
        csv = getTlaQuery("tlaCsv"),
        zn = getTlaQuery("tlaZn");

      if (cta && csv && zn) {
        var arg2 = {
          et: "ClientConversion",
          fp: tla.fp,
          usid: tla.userSession,
          pid: tla.propertyId,
          ts: tla.timestamp,
          url: tla.currPage,
          tz: (new Date()).getTimezoneOffset() / 60,
          cta: cta,
          csv: csv,
          zn: zn,
          val: tla.currPage,
          valt: type
        };

        _tla("track", "event", arg2);
      }
    }
  };

  tla.conversion = function (type, value, cta, csv, zn) {
    var arg = {
      et: "Conversion",
      fp: tla.fp,
      usid: tla.userSession,
      pid: tla.propertyId,
      ts: tla.timestamp,
      url: tla.currPage,
      tz: (new Date()).getTimezoneOffset() / 60,
      cta: cta,
      csv: csv,
      zn: zn,
      val: value,
      valt: type
    };

    _tla("track", "event", arg);
  };

  tla.receiveContent = function (response, container, zoneId) {
    void 0;
    var respJson = JSON.parse(response);
    if (respJson && respJson.content) {
      var cntr = document.getElementById(container);
      cntr.innerHTML = respJson.content;

      var scripts = cntr.getElementsByTagName("script");
      for (var i = 0; i < scripts.length; ++i) {
        var script = scripts[i];
        eval(script.innerHTML);
      }

      if (respJson.templateId) {
        tla.viewedZones[zoneId] = false;

        var tlaSelfTracking = cntr.querySelector('input[name="tlaSelfTracking"]') != null ? true : false;

        addZoneClickTracker(container, respJson, zoneId, tlaSelfTracking);

        var data = {
          et: "AdvertLoad",
          fp: tla.fp,
          usid: tla.userSession,
          pid: tla.propertyId,
          ts: tla.timestamp,
          url: tla.currPage,
          tz: (new Date()).getTimezoneOffset() / 60,
          cta: respJson.templateId,
          zn: zoneId,
          prid: respJson.productId,
          cli: respJson.campaignLineItemId,
          csv: respJson.contentSetVersionId,
          cdtv: respJson.campaignDeliveryTypeIds.join(",")
        };
        _tla("track", "event", data);

        void 0;

        scrollStop(function () {
          tla.updateZoneViewStatus(container, respJson, zoneId);
        });

        if (tla.isIframe) {
          tla.updateZoneViewStatus(container, respJson, zoneId);
        }
      }
    }
  };

  tla.getContent = function (zoneId, container) {
    if (tla.propOk) {
      //##TODO: need to add check for no specified container in which case output HTML to parent element

      var fp = tla.fp;
      var uri = tlaServiceUri + "content/?propertyid=" + tla.propertyId + "&fp=" + fp + "&zoneid=" + zoneId + "&ts=" + tla.timestamp + "&url=" + encodeURI(tla.currPage) + "&clientwidth=" + document.body.clientWidth + "&context=" + encodeURI(tla.context);

      getByXhr(uri, tla.receiveContent, container, zoneId);
    }
  };

  tla.iframe = function (zoneId, multiFrame, top) {
    tla.multiFrame = multiFrame;
    void 0;
  };

  tla.templateContentClick = function (eventType, e, templateId, productId, campaignLineItemId, contentSetVersionId, zoneId, campaignDeliveryTypeIds) {
    e = e || window.event;
    var elm = e.target || e.srcElement;
    var cls = elm.className;
    if (typeof cls !== "string") {
      cls = "";
    }

    var arg = {
      et: eventType,
      fp: tla.fp,
      usid: tla.userSession,
      pid: tla.propertyId,
      ts: tla.timestamp,
      url: tla.currPage,
      tz: (new Date()).getTimezoneOffset() / 60,
      cta: templateId,
      prid: productId,
      cli: campaignLineItemId,
      csv: contentSetVersionId,
      zn: zoneId,
      tgt: elm.href || elm.id || cls || elm.nodeName,
      fid: elm.id || cls || elm.nodeName,
      fc: elm.nodeName,
      cdtv: campaignDeliveryTypeIds
    };
    _tla("track", "event", arg);
    return true;
  };

  tla.propOk = false;
  //END TLA OBJECT

  //OTHER FUNCTIONS
  function ImpressionTracker(obj, args) {
    var self = this;

    function setData(a, b) {
      self.data[a] = b;
    }

    self.data = {};
    setData("et", 8),
      setData("fp", args),
      setData("usid", obj.userSession),
      setData("pid", obj.propertyId),
      setData("ts", obj.timestamp),
      setData("url", obj.currPage),
      setData("tz", (new Date()).getTimezoneOffset() / 60),
      setData("pd", navigator.product),
      setData("an", navigator.appName),
      setData("vs", navigator.version),
      setData("plt", navigator.platform),
      setData("cs", navigator.characterSet),
      setData("lg", navigator.language),
      setData("ag", navigator.userAgent),
      setData("cw", document.body.clientWidth),
      setData("ch", document.body.clientHeight),
      setData("sw", screen.width),
      setData("sh", screen.height),
      setData("cm", document.compatMode),
      setData("cc", screen.colorDepth),
      setData("pxd", screen.pixelDepth);
  }

  return {
    init: function () {
      void 0;
      domReady(function () {
        var t = tla.initialise;
        t.apply();
      });

      //##TODO - need to add check for document visible and add listener so we don't try running tracking etc without document loaded.
      void 0;
    }
  };
})(window);

tlaInit.init();